import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Seo from "../../components/Seo"
import Layout from "../../components/layout/Layout"
import HeaderPlain from "../../components/HeaderPlain"
import GetUpdates from "../../components/GetUpdates"

const PartnersPage = ({ data }) => {
  return (
    <>
      <Seo title="Partners" bodyClass="page__partners presentation" />
      <Layout>
        <section className="layout__main s__white partners">
          <div className="nav__spacer"></div>
          <HeaderPlain title="Partners" />
          <div className="u__container u__vspacing">
            <div className="row u__spacing team py-0">
              <div className="col-sm-9 col-lg-8">
                <div className="partners__intro">
                  <p>The Policy Lab has developed partnerships with government entities and organizations across the country that are dedicated to evidence-based policymaking. Check out who we’ve worked with and what we’ve accomplished together below:</p>
                </div>
                {data.allContentfulPartner.nodes.filter(node => node.display === true).map(node => {
                  const image = getImage(node.logo.gatsbyImageData)
                  return (
                    <div className="partner row" key={node.contentful_id}>
                      {node.url ? (
                        <a href={node.url} className="col-5 mx-auto mb-4 col-xs-3 mx-xs-0 mb-xs-0 col-lg-2 text-center" target="_blank" rel="nofollow noreferrer">
                          <span className="sr-only">
                            {`Visit ${node.name} website in a new window`}
                          </span>
                          <GatsbyImage
                            image={image}
                            className="partner__image"
                            alt=""
                            objectFit="contain"
                            aria-hidden="true"
                          />
                        </a>
                      ) : (
                        <GatsbyImage
                          image={image}
                          className="partner__image col-5 mx-auto mb-4 col-xs-3 mx-xs-0 mb-xs-0 col-lg-2"
                          alt=""
                          objectFit="contain"
                          aria-hidden="true"
                        />
                      )}
                      <div className="partner__info col-12 col-xs-9 col-lg-10">
                        <h2 className="partner__name h1 h3">{node.name}</h2>
                        <p>Referenced in the following:</p>
                        <ul className="partner__project-list">
                          {node.project.map(( project ) => {
                            return (
                              <li className="partner__project-item" key={project.contentful_id}>
                                <Link to={`/projects/${project.slug}`} className="partner__project-link">
                                  {project.title}
                                </Link>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </section>
        <GetUpdates sectionClass="s__light" />
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  {
    allContentfulPartner(sort: {fields: name}) {
      nodes {
        contentful_id
        name
        logo {
          gatsbyImageData(height: 125)
        }
        url
        project {
          contentful_id
          title
          slug
        }
        display
      }
    }
  }
`

export default PartnersPage
